export function generateRandomPassword() {
      const numbers = '0123456789'
      const specialChars = '!@#$%^&*()_+{}:"<>?|[];\',./`~'
      const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz'

      // Guaranteed to include at least one character from each required group
      const guaranteedCharacters = [
            numbers[ Math.floor(Math.random() * numbers.length) ],
            specialChars[ Math.floor(Math.random() * specialChars.length) ],
            upperCaseLetters[ Math.floor(Math.random() * upperCaseLetters.length) ],
            lowerCaseLetters[ Math.floor(Math.random() * lowerCaseLetters.length) ]
      ]

      const allCharacters = numbers + specialChars + upperCaseLetters + lowerCaseLetters
      const remainingLength = 8 - guaranteedCharacters.length

      let randomCharacters = guaranteedCharacters.join('')
      for (let i = 0; i < remainingLength; ++i) {
            randomCharacters += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length))
      }

      // Shuffle the guaranteed characters into the rest of the password to ensure randomness
      randomCharacters = randomCharacters.split('').sort(() => 0.5 - Math.random()).join('')

      return randomCharacters
}
