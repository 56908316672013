import { useContext } from 'react'
import { MyContext } from '../App'  // Adjust the path as necessary

// Hook to set loading state in AppData
export const useLoading = () => {
      const { AppData, setAppData } = useContext(MyContext)

      const setLoading = (loading) => {
            setAppData({ loading })
      }

      return [ AppData.loading, setLoading ]
}
