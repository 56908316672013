import { useContext } from "react"
import { Row, Col } from "antd"
import Divider from '../../ui/Divider'
import Logo from '../../ui/Logo'
import styles from './Footer.module.css'
import { MyContext } from "../../../App"

export function Footer() {

      const { UserData } = useContext(MyContext)

      const vw = UserData?.device?.viewport?.width || false

      return (

            <div id={styles.Footer}>

                  <Row gutter={16} justify="center" align="top">

                        <Col xs={23} md={20}>

                              <Row>

                                    <Col xs={24} md={6} style={vw <= 768 ? { marginBottom: 50 } : {}}>

                                          <Logo color="rgba(255,255,255,0.2)" height="20" />

                                    </Col>

                                    <Col xs={24} md={10} style={{ paddingLeft: 25, borderLeft: '1px solid rgba(255,255,255,0.1)', marginBottom: vw <= 768 ? 50 : null }} >

                                          <a href="#">Link #1</a>
                                          <a href="#">Link #2</a>
                                          <a href="#">Link #3</a>
                                          <a href="#">Link #4</a>
                                          <a href="#">Link #5</a>

                                    </Col>

                                    <Col xs={24} md={8}>

                                          <div>
                                                © 2024 Voli Ltd. All rights reserved.
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                Mover Insurance is a trading style of Voli Limited. Voli Limited is authorised and regulated by the Financial Conduct Authority. Registration 8363739
                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                  </Row >

            </div >

      )
}