import { Row, Col } from "antd"
import styles from './BannerCta.module.css'
// import { RiContactsLine as ContactIcon } from "react-icons/ri"
import { SiWikiquote as ContactIcon } from "react-icons/si"

export function BannerCta() {

      return (

            <div id={styles.BannerCta} style={{ width: '100%' }}>

                  <Row justify="center">

                        <Col span={18}>

                              <Row align="middle">

                                    <Col flex="100px">

                                          <ContactIcon style={{ fontSize: 64, opacity: 0.1 }} />

                                    </Col>

                                    <Col>

                                          <h2>Call to action</h2>
                                          <p>Some engaging text that makes me take action...</p>

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

            </div>

      )

}