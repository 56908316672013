import { lazy, Suspense, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { MyContext } from '../../../App'

// Dynamic imports using lazy
const SetupCourier = lazy(() => import('./Courier'))

const Setup = () => {

      const navigate = useNavigate()

      const { UserData } = useContext(MyContext)

      // Route Protection
      useEffect(() => {
            if (!UserData?.policy?.driverType) { navigate("/") }
      }, [ UserData, navigate ])

      function renderForm(driverType) {
            switch (driverType) {
                  case 'courier':
                        return <SetupCourier />
                  // case 'remover':
                  //       return <QuoteRemover />
                  // case 'man-van':
                  //       return <QuoteManVan />
                  // case 'vehicle-transporter':
                  //       return <QuoteVehicleTransporter />
                  // case 'truck-haulage':
                  //       return <QuoteTruckHaulage />
                  default:
                        return <p>Driver type not supported!</p>
            }
      }

      return (
            <Suspense fallback={<div>Loading...</div>}>
                  {renderForm(UserData.policy.driverType)}
            </Suspense>
      )

}

export default Setup