import { useNavigate } from "react-router-dom"

export default function Logo({ height = 40, color = 'rgba(0,0,0,1)' }) {

      const navigate = useNavigate()

      return (

            <div className="clickable" onClick={() => navigate("/")}>
                  <svg height={height} viewBox="0 0 500 111" xmlns="http://www.w3.org/2000/svg">
                        <g fill={color}>
                              <path d="M48.2,1.1l16.4,52.8L99.8,1.1h28.9l-19.4,110.2H81.6l11-62.1L69,86.2H49l-10.4-37l-11,62.1H0L19.4,1.1H48.2z" />
                              <path d="m186.7 0c24.6 0 36.9 10.1 36.9 30.2 0 3.4-0.4 7-1.1 10.9l-5.4 30.6c-2.4 14.2-7.8 24.7-15.9 31.5-8.2 6.8-19.6 10.3-34.4 10.3s-25.1-3.4-30.9-10.3c-4-4.7-6-11.2-6-19.4 0-3.7 0.4-7.7 1.2-12.1l5.4-30.6c4.8-27.4 21.6-41.1 50.2-41.1zm-15.7 89.7c5.1 0 9.2-1.3 12.2-4 3.1-2.7 5.2-7.6 6.4-14.7l5.2-29.4c0.3-2 0.5-3.9 0.5-5.5 0-8.3-4.2-12.4-12.6-12.4-10.5 0.2-16.8 6.2-18.8 17.9l-5.2 29.4c-0.4 2.6-0.6 5.2-0.6 8 0 7.1 4.3 10.7 12.9 10.7z" />
                              <path d="M268.9,84.5L298,1.1h29.8l-41.8,110.2h-49L235.9,1.1h30l-0.5,83.4H268.9z" />
                              <path d="m469.7 1.1c11.6 0 19.9 2.6 24.8 7.7 3.7 3.9 5.5 8.8 5.5 14.7 0 2-0.2 4.2-0.6 6.4l-2.1 11.9c-1.2 6.6-3.3 12-6.4 16.1-3 4.1-6.4 7-10 8.7l13.9 44.7h-30.9l-12.9-41.2h-9.5l-6.3 41.2h-29.4l19.5-110.2h44.4zm-9.7 23.9h-11.5l-4 26.6h10.7c5.4 0 9.3-1.3 11.6-3.9s3.5-6 3.5-10.3c0.2-8.3-3.3-12.4-10.3-12.4z" />
                              <polygon points="323.5 87.4 319.3 111.3 390.5 111.3 394.7 87.4" />
                              <polygon points="394.7 45.6 339.5 45.6 335.3 69.5 390.5 69.5" />
                              <polygon points="410 1.1 338.7 1.1 334.5 25 405.8 25" />
                        </g>
                  </svg>
            </div >

      )

}
