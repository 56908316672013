export const config = {
      stage: {
            api: {
                  baseUrl: 'stage-api.moverinsurance.uk'
            },
            aws: {
                  cognito: {
                        clientId: '89o2lc13lnsskq77elm7ntf1n'
                  }
            }
      },
      prod: {
            api: {
                  baseUrl: 'api.moverinsurance.uk'
            },
            aws: {
                  cognito: {
                        clientId: '55hi357u8kpgr4k9r9q26doo4c'
                  }
            }
      }
}