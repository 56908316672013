import { useState } from 'react'
import { generateRandomPassword } from "./helper"
import client from './CognitoClient'
import {
      SignUpCommand,
      ConfirmSignUpCommand,
      InitiateAuthCommand,
      UpdateUserAttributesCommand
} from '@aws-sdk/client-cognito-identity-provider'
import { config } from '../../../_config/config' // Adjust the path to where your config is stored

const ClientId = window.location.hostname.includes('stage') || window.location.hostname.includes('local')
      ? config.stage.aws.cognito.clientId
      : config.prod.aws.cognito.clientId

const password = generateRandomPassword()

export default function Signup() {
      const [ phoneNumber, setPhoneNumber ] = useState('')
      const [ email, setEmail ] = useState('')
      const [ code, setCode ] = useState('')
      const [ verificationStep, setVerificationStep ] = useState('initial')

      const handleSignUp = async () => {

            try {
                  await client.send(new SignUpCommand({
                        ClientId,
                        Username: phoneNumber, // Phone number as the username
                        Password: password,
                        UserAttributes: [
                              { Name: 'phone_number', Value: phoneNumber },
                              { Name: 'email', Value: email } // Email as a required attribute for recovery
                        ]
                  }))
                  alert('Please verify your phone number.')
                  setVerificationStep('verifyPhone')
            } catch (error) {
                  console.error('Error during signup:', error)
                  alert(error.message)
            }
      }

      const handleVerifyPhone = async () => {
            try {
                  await client.send(new ConfirmSignUpCommand({
                        ClientId,
                        Username: phoneNumber,
                        ConfirmationCode: code
                  }))
                  alert('Phone number verified successfully!')
                  setVerificationStep('verifyEmail') // Next step: verify email
            } catch (error) {
                  console.error('Error verifying phone:', error)
                  alert(error.message)
            }
      }

      const handleVerifyEmail = async () => {
            try {
                  // You may need to log in the user and get an access token first if not already logged in
                  const loginResponse = await client.send(new InitiateAuthCommand({
                        AuthFlow: 'USER_PASSWORD_AUTH',
                        ClientId,
                        AuthParameters: {
                              USERNAME: phoneNumber,
                              PASSWORD: password,
                        }
                  }))

                  console.log(JSON.stringify(loginResponse))
                  const accessToken = loginResponse.AuthenticationResult.AccessToken


                  await client.send(new UpdateUserAttributesCommand({
                        AccessToken: accessToken,
                        UserAttributes: [
                              { Name: 'email_verified', Value: 'true' } // Mark email as verified
                        ]
                  }))
                  alert('Email verified successfully!')
                  setVerificationStep('complete')
            } catch (error) {
                  console.error('Error verifying email:', error)
                  alert(error.message)
            }
      }

      return (
            <div>
                  {verificationStep === 'initial' && (
                        <>
                              <input
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}
                                    placeholder="Phone number"
                                    type="tel"
                              />
                              <input
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder="Email"
                                    type="email"
                              />
                              <button onClick={handleSignUp}>Sign Up</button>
                        </>
                  )}

                  {verificationStep === 'verifyPhone' && (
                        <>
                              <input
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    placeholder="Verification code"
                              />
                              <button onClick={handleVerifyPhone}>Verify Phone Number</button>
                        </>
                  )}

                  {verificationStep === 'verifyEmail' && (
                        <>
                              <button onClick={handleVerifyEmail}>Verify Email</button>
                        </>
                  )}

                  {verificationStep === 'complete' && (
                        <p>Complete</p>
                  )}
            </div>
      )
}
