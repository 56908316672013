import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"

import { Hero } from "../../sections/Hero"
import { LogoCarousel } from "../../sections/LogoCarousel"
import { Features } from "../../sections/Features"
import { Products } from "../../sections/Products"
import { Reviews } from "../../sections/Reviews"
import { BannerCta } from "../../sections/BannerCta"

function Home() {

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>
                        <Hero />
                  </Content>

                  <Content>
                        <LogoCarousel itemNames={[ "amazon", "culina" ]} />
                        <Features />
                        <Products />
                        <Reviews />
                  </Content>

                  <Content isFullWidth >
                        <BannerCta style={{ marginTop: 0 }} />
                  </Content>

                  <Footer />

            </>

      )
}

export default Home