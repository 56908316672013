import { Row, Col } from "antd"
import styles from './Content.module.css'

export function Content({ children, rowProps = {}, colProps = {}, isFullWidth = false }) {

      // Style object for full-width sections
      const fullWidthStyle = {
            overflowX: 'hidden',
            width: '100%',
            margin: '0',
            paddingBottom: 0,
            // marginTop: -100
      }

      // Default props for the Row
      const defaultRowProps = {
            gutter: 16,
            justify: "center",
            align: "top",
            ...rowProps // Overrides defaults if rowProps contains any of the same keys
      }

      // Default props for the Col
      const defaultColProps = {
            xs: 23,
            sm: 23,
            md: 23,
            lg: 22,
            xl: 21,
            ...colProps // Overrides defaults if colProps contains any of the same keys
      }

      // Apply full-width style if isFullWidth is true, otherwise use default styles
      const contentStyle = isFullWidth ? fullWidthStyle : {}

      return (

            <div id={styles.Content} style={contentStyle}>

                  {/* If isFullWidth is true, don't wrap children with a Row and Col */}
                  {isFullWidth ? children : (

                        <Row {...defaultRowProps}>

                              <Col {...defaultColProps}>{children}</Col>

                        </Row>

                  )}

            </div>

      )
}