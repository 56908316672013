import { Row, Col } from "antd"
import styles from './Features.module.css'

export function Features() {

      return (

            <div id={styles.Features}>

                  <Row style={{ marginTop: 175, marginBottom: 120 }}>

                        <Col span={6}>

                              <div className="superscript">PRODUCT FEATURE</div>

                        </Col>

                        <Col span={12}>

                              <h2 style={{ marginTop: -20, marginBottom: 0 }}>Make your body energetic and more superpower</h2>
                              <p style={{ maxWidth: '80%' }}>Is a nutritional supplement for increased health and vitality. Featuring 1000mg of organically-grown, cannabidiol flavour.</p>

                        </Col>

                  </Row>

            </div>

      )
}