import { notification } from 'antd'

export const notify = (type, title, description, duration = 4.5) => {
      notification[ type ]({
            message: title,
            description: description,
            duration: duration,
            icon: (<div></div>)
      })
}
