import { lazy, Suspense, } from 'react'
import { useParams } from "react-router-dom"

// Dynamic imports using lazy
const Type = lazy(() => import('./Type'))
const QuoteCourier = lazy(() => import('./Courier'))
const QuoteRemover = lazy(() => import('./Remover'))
const QuoteManVan = lazy(() => import('./ManVan'))
const QuoteVehicleTransporter = lazy(() => import('./VehicleTransporter'))
const QuoteTruckHaulage = lazy(() => import('./TruckHaulage'))

const Quote = () => {

      const { driverType } = useParams()

      function renderForm(driverType) {
            switch (driverType) {
                  case 'type':
                        return <Type />
                  case 'courier':
                        return <QuoteCourier />
                  case 'remover':
                        return <QuoteRemover />
                  case 'man-van':
                        return <QuoteManVan />
                  case 'vehicle-transporter':
                        return <QuoteVehicleTransporter />
                  case 'truck-haulage':
                        return <QuoteTruckHaulage />
                  default:
                        return <p>Driver type not supported!</p>
            }
      }

      return (
            <Suspense fallback={<div>Loading...</div>}>
                  {renderForm(driverType)}
            </Suspense>
      )
}

export default Quote