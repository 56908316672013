import React, { useEffect, useState } from 'react'

export const Countdown = ({ value }) => {

      const [ count, setCount ] = useState(value)

      useEffect(() => {

            if (count <= 0) return

            const timer = setTimeout(() => setCount(count - 1), 1000)

            return () => clearTimeout(timer)

      }, [ count ])

      return <span>{count}</span>
}
