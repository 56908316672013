// Import the config module
import { config } from '../_config/config' // Adjust the path to where your config is stored

// Determine the base URL based on the window.location or some environment flag
const baseUrl = window.location.hostname.includes('stage') || window.location.hostname.includes('local')
      ? config.stage.api.baseUrl
      : config.prod.api.baseUrl

/**
 * Makes a POST request to the /read endpoint.
 * @param {Object} data - The data to be sent in the POST request.
 * @returns {Promise<Object>} The response from the server.
 */
export async function read(data) {
      try {
            // Specify the API endpoint
            const endpoint = `https://${baseUrl}/read`

            // Make a POST request with fetch
            const response = await fetch(endpoint, {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(data)
            })

            // Check if the request was successful
            if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`)
            }

            // Return the response data as JSON
            return await response.json()

      } catch (error) {
            // Handle errors
            console.error('Error in read function:', error)
            throw error // Re-throw the error to be handled by the caller
      }
}

export async function post(path, data = {}) {
      try {
            // Specify the API endpoint
            const endpoint = `https://${baseUrl}${path}`

            // Make a POST request with fetch
            const response = await fetch(endpoint, {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(data)
            })

            // Check if the request was successful
            if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`)
            }

            // Return the response data as JSON
            return await response.json()

      } catch (error) {
            // Handle errors
            console.error('Error in read function:', error)
            throw error // Re-throw the error to be handled by the caller
      }
}
