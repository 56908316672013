import { Row, Col, Button, Space } from "antd"
import styles from './Hero.module.css'
import { useNavigate } from "react-router-dom"
import { notify } from "../../../_utils/ux"
import { Countdown } from "../../ui/Countdown"
import { useLoading } from '../../../hooks/useLoading'
import { read } from "../../../_utils/crud"



export function Hero() {

      const [ isLoading, setLoading ] = useLoading()

      const navigate = useNavigate()

      const handleNotify = () => {
            const description = (<span>You will be terminated in <Countdown value={3} /> seconds</span>)
            notify('info', 'Standby...', description, 4)
      }

      const handleLoading = async () => {
            setLoading(true)
            try {
                  // Simulate an async operation, e.g., fetching data
                  notify('info', 'Waiting...', 'Making API call', 2)
                  await new Promise(resolve => setTimeout(resolve, 2000))
            } finally {
                  const data = {
                        table: "validation"
                  }
                  const results = await read(data)
                  console.log(results)
                  notify('info', 'Complete...', `<span>${JSON.stringify(results.result[ 0 ])}</span>`, 4)
                  setLoading(false)
            }
      }

      return (

            <div id={styles.Hero}>

                  <Row justify="center">

                        <Col xs={23} md={20}>

                              <Row justify="center">

                                    <Col span={24}>

                                          <div className="superscript" style={{ marginTop: 0, marginLeft: 8 }}>FOR PROFESSIONAL DRIVERS</div>

                                          <h1 style={{ marginTop: 20, fontSize: 104, marginBottom: 25 }}>Super</h1>

                                          <Row>
                                                <Col flex="250px">
                                                      <Space direction="vertical">
                                                            <Button type="primary" block size="large" onClick={() => { navigate("/quote/type") }}>Quote</Button>
                                                            <Button type="primary" block size="large" onClick={() => handleNotify()}>Notify</Button>
                                                            <Button type="primary" block size="large" onClick={() => handleLoading()}>{isLoading ? 'Loading...' : 'Click Me'}</Button>
                                                      </Space>
                                                </Col>
                                          </Row>

                                    </Col>

                                    <Col span={7}>

                                          {/* <img src="/assets/images/hero.png" alt="Award" style={{ transform: 'rotate(-10deg)', marginTop: -95, width: 250, marginBottom: -150 }} /> */}

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

            </div>

      )
}